
  import { channelColors } from '@/utils/channelColors'
  import { deepCopy, localToUtc, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component, Prop } from 'vue-property-decorator'
  import StaffDashboard from './StaffDashboard'

@Component
  export default class StaffSalesPurchases extends StaffDashboard {
  @Prop({ default: true, type: Boolean }) showGeneralResults

// Methods
  beforeCreate () {
    this.getData = async () => {
      this.$set(this.specificChartData.currentMonth, 'leads', await this.getLeads(this.specificCurrentMonthFilters.leads))
      this.$set(this.specificChartData.currentMonth, 'purchases', await this.getPurchases(this.specificCurrentMonthFilters.stockCreatedDate))
      this.$set(this.specificChartData.currentMonth, 'sales', await this.getSales(this.specificCurrentMonthFilters.startEndDate))
      this.$set(this.specificChartData.currentMonth, 'reserves', await this.getReserves(this.specificCurrentMonthFilters.startEndDate))
      this.$set(this.specificChartData.currentMonth, 'soldPurchases', await this.getPurchases(this.specificCurrentMonthFilters.soldPurchases))
      this.$set(this.specificChartData.currentMonth, 'soldSales', await this.getSoldSales(this.specificCurrentMonthFilters.soldSales))
      this.$set(this.specificChartData.currentMonth, 'utilityCredits', await this.getUtilityCredits(this.specificCurrentMonthFilters.utilityCredits))
      this.$set(this.specificChartData.currentMonth, 'signedCredits', await this.getLoanEvaluationsAggregates(this.specificCurrentMonthFilters.signedCredits))

      this.$set(this.specificChartData.lastYear, 'purchases', await this.getPurchases(this.specificLastYearFilters.stockCreatedDate))
      this.$set(this.specificChartData.lastYear, 'sales', await this.getSales(this.specificLastYearFilters.startEndDate))
      this.$set(this.specificChartData.lastYear, 'signedCredits', await this.getLoanEvaluationsAggregates(this.specificLastYearFilters.signedCredits))
      this.$set(this.chartData.currentMonth, 'stock', await this.getStock(this.currentMonthFilters.stockSpecs))
    }
  }

  fixPurchaseFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { auto, executive } = corrected[i]
      const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
      const executiveFullName = `${executive.person.firstName.trim()} ${executive.person.surname.trim()}`
      corrected[i].stockCreated = dayjs(corrected[i].stockCreated).format('DD/MM/YY - HH:mm')
      corrected[i].executive.fullName = executiveFullName
      corrected[i] = {
        ...corrected[i],
        carFullName,
        executiveFullName,
      }
    }

    return corrected
  }

  fixCreditFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { auto, executive } = corrected[i]
      const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
      const executiveFullName = `${executive.person.firstName.trim()} ${executive.person.surname.trim()}`
      corrected[i].signedDate = dayjs(corrected[i].signedDate).format('DD/MM/YY - HH:mm')
      corrected[i].executive.fullName = executiveFullName
      corrected[i] = {
        ...corrected[i],
        carFullName,
        executiveFullName,
      }
    }

    return corrected
  }

  fixSaleFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { autoDescription, year, executive, executiveName } = corrected[i]
      const carFullName = `${autoDescription} ${year}`
      corrected[i].soldDate = dayjs(corrected[i].soldDate).format('DD/MM/YY - HH:mm')
      corrected[i].executive = {
        id: executive,
        name: executiveName,
      }
      corrected[i] = {
        ...corrected[i],
        carFullName,
      }
    }

    return corrected
  }

  setChannelColors (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { channel } = corrected[i]
      channel.color = channelColors[channel.name]
      corrected[i].channel = channel
    }
    return corrected
  }

  transformUtility (records) {
    const { getObjectAttribute } = this
    const { start, end } = this.lastYearDates

    const recordsCopy = deepCopy(records)

    for (let i = 0; i < recordsCopy.length; i++) {
      this.$set(recordsCopy[i], 'records', recordsCopy[i].records?.records?.nodes || recordsCopy[i].records?.records || recordsCopy[i].records?.records || recordsCopy[i].records || [])
    }

    const purchasesRecords = recordsCopy?.filter(record => record.name === 'Compras')[0]
    const salesRecords = recordsCopy?.filter(record => record.name === 'Ventas')[0]
    const creditsRecords = recordsCopy?.filter(record => record.name === 'Créditos')[0]

    if (!purchasesRecords || !salesRecords || !creditsRecords) return

    let newDate = dayjs(start)
    const limitRange = [newDate]
    while (newDate.isBefore(dayjs(end))) {
      newDate = newDate.add(1, 'month')
      if (newDate.isBefore(dayjs(end))) {
        limitRange.push(newDate)
      }
    }
    // Directa
    const directUtility = limitRange.map(date => {
      const sales = salesRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, salesRecords.pathToDate))).isSame(date, 'month'))
      const purchases = purchasesRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, purchasesRecords.pathToDate))).isSame(date, 'month'))
      let utility = 0
      sales.forEach(sale => utility += getObjectAttribute(sale, salesRecords.pathToValue) || 0)
      purchases.forEach(purchase => utility -= getObjectAttribute(purchase, purchasesRecords.pathToValue) || 0)
      return {
        date: localToUtc(date).format('YYYY-MM-DDTHH:mm:ss'),
        utility,
      }
    })
    // Creditos
    const creditsUtility = limitRange.map(date => {
      const credits = creditsRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, creditsRecords.pathToDate))).isSame(date, 'month'))
      let utility = 0
      credits.forEach(credit => utility += getObjectAttribute(credit, creditsRecords.pathToValue))
      return {
        date: date.format('YYYY-MM-DD'),
        utility,
      }
    })

    return [
      {
        name: 'Directa',
        records: directUtility,
        color: '#FAD901',
        pathToDate: ['date'],
        pathToValue: ['utility'],
        type: 'bar',
      },
      {
        name: 'Créditos',
        records: creditsUtility,
        color: '#7D67BC',
        pathToDate: ['date'],
        pathToValue: ['utility'],
        type: 'bar',
      },
    ]
  }

  // Getters
  get targetMultiplier () {
    const executives = this.chartData?.settings?.executives?.records
    return 1.2 / (executives?.length || 1)
  }

  // Watchers
  }
